<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">鉴定机构信息</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">机构信息</a>
        </span>
      </div>
      <div class="framePage-body">
        <!-- <el-tabs v-model="activeName" :before-leave="beforeTabLeave" @tab-click="tabsChange">
          <el-tab-pane label="机构简介" name="first">
            <briefIntroduction ref="first" />
          </el-tab-pane>
          <el-tab-pane label="收费公示" name="second">
            <feePublicity ref="second" />
          </el-tab-pane>
          <el-tab-pane label="交费公示" name="three">
            <paymentMethod ref="three" />
          </el-tab-pane>
        </el-tabs> -->
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-form
              ref="Form"
              :model="Form"
              :rules="rules"
              label-width="120px"
              class
            >
              <el-form-item
                label="机构名称："
                prop="compName"
                class="form-item"
              >
                <el-input
                  v-model="Form.compName"
                  placeholder="请输入机构名称"
                  disabled
                  size="small"
                  maxlength="50"
                  show-word-limit
                />
              </el-form-item>
              <el-form-item
                label="机构logo："
                prop="compLogoUrl"
                class="form-item"
              >
                <el-upload
                  :on-change="handleAvatarSuccess2"
                  :before-upload="beforeAvatarUpload2"
                  :http-request="$requestMine"
                  :show-file-list="false"
                  class="img-el-upload"
                  action
                  accept="image/png, image/gif, image/jpg, image/jpeg"
                >
                  <el-image
                    :src="Form.compLogoUrl || require('@/assets/develop.png')"
                    fit="contain"
                    class="imgCenter"
                  ></el-image>
                </el-upload>
              </el-form-item>
              <el-form-item
                label="机构展示："
                class="form-item"
              >
                <el-upload
                  :on-change="handleAvatarSuccess3"
                  :before-upload="beforeAvatarUpload2"
                  :http-request="$requestMine"
                  :show-file-list="false"
                  class="img-el-upload"
                  action
                  accept="image/png, image/gif, image/jpg, image/jpeg"
                >
                  <el-image
                    :src="Form.compShowUrl || require('@/assets/develop.png')"
                    fit="contain"
                    class="imgCenter"
                  ></el-image>
                </el-upload>
              </el-form-item>
              <el-form-item label="联系人：" prop="compUser" class="form-item">
                <el-input
                  v-model="Form.compUser"
                  placeholder="请输入联系人"
                  size="small"
                  maxlength="50"
                  show-word-limit
                />
              </el-form-item>
              <el-form-item
                label="联系电话："
                prop="compPhone"
                class="form-item"
              >
                <el-input
                  v-model="Form.compPhone"
                  placeholder="请输入联系电话"
                  size="small"
                  maxlength="20"
                  show-word-limit
                />
              </el-form-item>
              <el-form-item
                label="评定区域："
                prop="evaluateAreaId"
                class="form-item"
              >
                <el-cascader
                  v-model="Form.evaluateAreaId"
                  placeholder="请选择行政区划"
                  :options="areaTreeList"
                  :props="propsArea"
                  size="small"
                  @change="handleChange"
                ></el-cascader>
              </el-form-item>
              <el-form-item label="机构简介：" prop="context" class="form-item">
                <div
                  ref="editor"
                  class="editor"
                  style="width: 100%; max-width: 1200px"
                />
              </el-form-item>
              <el-form-item
                label="营业执照："
                prop="compLicenseUrl"
                class="form-item"
              >
                <el-upload
                  :on-change="handleAvatarSuccess4"
                  :before-upload="beforeAvatarUpload2"
                  :http-request="$requestMine"
                  :show-file-list="false"
                  class="img-el-upload"
                  action
                  accept="image/png, image/gif, image/jpg, image/jpeg"
                >
                  <el-image
                    :src="
                      Form.compLicenseUrl || require('@/assets/develop.png')
                    "
                    fit="contain"
                    class="imgCenter"
                  ></el-image>
                </el-upload>
              </el-form-item>
              <el-form-item
                label="联系老师："
                prop="qrCode"
                class="form-item"
              >
                <el-upload
                  :on-change="handleAvatarSuccess5"
                  :before-upload="beforeAvatarUpload2"
                  :http-request="$requestMine"
                  :show-file-list="false"
                  class="img-el-upload"
                  action
                  accept="image/png, image/gif, image/jpg, image/jpeg"
                >
                  <el-image
                    :src="
                      Form.qrCodeUrl || require('@/assets/develop.png')
                    "
                    fit="contain"
                    class="imgCenter"
                  ></el-image>
                </el-upload>
              </el-form-item>
            </el-form>
            <div class="bottom-btn" style="display:flex;justify-content: center;">
              <el-button @click="seveData('Form')" class="bgc-bv"
                >保存</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import List from "@/mixins/List";
import { mapGetters } from "vuex";
import E from "wangeditor";
let editor = {};
// import briefIntroduction from "@/views/appraisalInstitution/InstitutionalInformation/briefIntroduction.vue";
// import feePublicity from "@/views/appraisalInstitution/InstitutionalInformation/feePublicity.vue";
// import paymentMethod from "@/views/appraisalInstitution/InstitutionalInformation/paymentMethod.vue";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "CourseReview",
  components: {
    // briefIntroduction,
    // feePublicity,
    // paymentMethod,
  },
  mixins: [List],
  data() {
    return {
      // activeName: "first"
      Form: {
        compName: "", //机构名称
        context: "", //简介
        context1: "", //简介
        compLogoUrl: "", //机构logoURL
        compLogo: "", //机构logoKEY
        compShowUrl: "", //机构展示URL
        compShow: "", //机构展示KEY
        compLicenseUrl: "", //营业执照URL
        compLicense: "", //营业执照KEY
        compUser: "", //联系人
        compPhone: "", //联系电话
        evaluateAreaId: "",
        qrCode: "",    //联系老师KEY
        qrCodeUrl: "", //联系老师URL
      },
      rules: {
        compName: [
          { required: true, message: "请输入机构名称", trigger: "blur" },
        ],
        compLogoUrl: [
          { required: true, message: "请上传机构Logo", trigger: "change" },
        ],
        compUser: [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
        compPhone: [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
        evaluateAreaId: [
          { required: true, message: "请选择评定区域", trigger: "change" },
        ],
        context: [
          { required: true, message: "请输入机构简介正文", trigger: "change" },
        ],
      },
      areaTreeList: [],
      propsArea: {
        value: "value",
        label: "label",
        disabled: "disabled",
        emitPath: false,
        checkStrictly: false,
      },
    };
  },
  created() {
    this.loadData();
    this.getAreaTree2();
    // this.getDelayList(this.projectId);
  },
  mounted() {
    this.editorInit();
  },
  computed: {
    ...mapGetters({
      userJson: "getUser",
    }),
  },
  methods: {
    getAreaTree2() {
      this.$post("/sys/area/tree?areaLevel=2").then((ret) => {
        this.areaTreeList = ret.data || [];
      });
    },
    // 富文本
    editorInit() {
      editor = new E(this.$refs.editor);
      editor.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        // "image", // 插入图片
        "table", // 表格
        "undo", // 撤销
        "redo", // 重复
      ];
      editor.config.zIndex = 100;
      editor.config.uploadImgShowBase64 = true;
      editor.config.uploadImgMaxSize = 1 * 1024 * 1024;
      editor.config.pasteFilterStyle = "text";
      editor.config.pasteIgnoreImg = true;

      editor.config.onchange = (html) => {
        this.Form.context = html;
        this.Form.context1 = editor.txt.text();
      };
      editor.config.pasteTextHandle = function (content) {
        return content + "<p></p>";
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
      };
      //  // 监听实时富文本输入内容
      //     editor.customConfig.onchange=(html)={
      //       let info=html;
      //       this.$emit('change',info);
      //     }
      editor.create();
      const eWidth = this.$refs.editor.clientWidth;
      this.eWidth = eWidth;
    },
    //获取机构信息
    loadData() {
      this.$post("/biz/evaluate/company/info", {})
        .then((ret) => {
          let data = ret.data;
          this.Form = {
            ...data,
            compLogoUrl: data.compLogoUrl || "", //机构logoURL
            compLogo: data.compLogo || "", //机构logoKEY
            compShowUrl: data.compShowUrl || "", //机构展示URL
            compShow: data.compShow || "", //机构展示KEY
            compLicenseUrl: data.compLicenseUrl || "", //营业执照URL
            compLicense: data.compLicense || "", //营业执照KEY
            context:data.introduction || '',
            context1:data.introduction || '',
            qrCode: data.qrCode || "", //营业执照KEY
            qrCodeUrl: data.qrCodeUrl || "", //营业执照URL
          };
          editor.txt.html(data.introduction);
        })
        .catch((err) => {
          return;
        });
    },
   
    //机构logo
    handleAvatarSuccess2(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            this.Form.compLogoUrl = result.data.fileURL;
            this.Form.compLogo = result.data.fileKey;
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    //机构展示
    handleAvatarSuccess3(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            this.Form.compShowUrl = result.data.fileURL;
            this.Form.compShow = result.data.fileKey;
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    //营业执照
    handleAvatarSuccess4(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            this.Form.compLicenseUrl = result.data.fileURL;
            this.Form.compLicense = result.data.fileKey;
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    //联系老师
    handleAvatarSuccess5(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "MATERIALS");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            this.Form.qrCodeUrl = result.data.fileURL;
            this.Form.qrCode = result.data.fileKey;
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    seveData(ruleForm) {
      const el = this.$refs.Form;
      el.validate((valid) => {
        if (!valid) {
          this.$message.warning("请检查必填项");
          ruleForm.clearValidate();
          return;
        }
        if (editor.txt.text().trim() == "") {
          this.$message.warning("请检查必填项");
          return;
        }
        var reg =
          /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/;
        if (reg.test(this.Form.context)) {
          this.$message.warning("不允许添加链接地址");
          return;
        }
        if (this.Form.context1.length > 2000) {
          this.$message.warning("文本长度不得超过2000字");
          return;
        }
        this.doAjax();
      });
    },
    doAjax() {
      let parmar = {
        evaluateAreaId: this.Form.evaluateAreaId,
        compUser: this.Form.compUser,
        compPhone: this.Form.compPhone,
        introduction: this.Form.context,
        compShow: this.Form.compShow,
        compLicense: this.Form.compLicense,
        compLogo:this.Form.compLogo,
        qrCode: this.Form.qrCode,
      };
      this.$post("/biz/evaluate/company/update", parmar)
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: ret.message,
              type: "success",
            });
            this.loadData()
          }
        })
        .catch((err) => {
          return;
        });
    },
  },
  // methods: {
  //   beforeTabLeave(newName) {
  //     this.$router.replace({
  //       path: this.$route.path,
  //       query: { active: newName }
  //     });
  //   },
  //   tabsChange(e) {
  //     if(e.name == 'first') {
  //        this.$refs[e.name].loadData();
  //     } else if(e.name == 'second') {
  //        this.$refs[e.name].loadData2();
  //     } else {
  //        this.$refs[e.name].loadData3();
  //     }

  //   }
  // },
  beforeRouteLeave: resetKeepAlive,
  // watch: {
  //   $route: function(route) {
  //     if (route.query.refresh) {
  //       this.$refs[route.query.activeName].getData(-1);
  //     }
  //   }
  // }
};
</script>
<style lang="less">
.searchBox {
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
</style>
